import { environment as defaultEnv } from './environment.portal-alignment';

export const environment = {
  ...defaultEnv,
  backendUri: 'https://sales-fr-api.hl-display.com/',
  production: true,
  defaultLanguage: 'fr',
  stripePkLive:
    'pk_live_51HdvCHDAfHGEgu0zT7pDQ53Or0YMDEn2oBOyFFDJeJfagF7TJ1sf4KtGhWjJihTDTdNx8q6QAXbtq1Tk40C06V4R00Sa1JcSp2'
};
